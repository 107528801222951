.banner {
    position: relative;
    height: 400px;
    /* Adjust height as needed */
    background: url('../../assets/banner/download.jpg') no-repeat center center/cover;
    /* Replace with your actual image path */
    color: #ffffff;
    /* White text for contrast */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 20px;
    /* Padding for responsive design */
}

.banner-content {
    max-width: 800px;
    /* Adjust based on your design */

    h1 {
        font-size: 3rem;
        /* Adjust font size as needed */
        margin-bottom: 15px;
        font-weight: bold;
    }

    p {
    font-size: 1.25rem;
    /* Adjust font size as needed */
    margin-bottom: 20px;
    color: #7e1212;
    /* Dark gray for paragraph text */
    }

    .btn-primary {
        background-color: #FFD700;
        /* Gold background for the button */
        color: #0a1f44;
        /* Dark navy blue text color */
        padding: 10px 20px;
        border-radius: 5px;
        text-decoration: none;
        font-size: 1rem;
        font-weight: bold;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
            background-color: #0a1f44;
            /* Dark navy blue background on hover */
            color: #FFD700;
            /* Gold text color on hover */
        }
    }
}
.page-title {
    text-align: center;
    max-width: 650px;
    margin: 150px auto 100px;

    h2 {
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 10px;
    }
}

// Small Device Responsive
@media (max-width: 575px){
    .page-title {
        margin: 100px auto 50px;
    
        h2 {
            font-size: 20px;
        }
    }
}
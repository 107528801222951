.products {
    padding: 100px 20px 70px;
    background-color: #fdfdfd;

    .media-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr); // Always 2 items per row
        gap: 20px;

        @media (max-width: 768px) {
            grid-template-columns: 1fr; // Stack items on small screens (mobile)
        }
    }

    .media-item {
        background-color: #f8f9fa;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
        }

        .media-image,
        .media-video {
            width: 100%;
            height: 300px;
            border-radius: 8px;
            object-fit: cover;
        }

        .media-video {
            outline: none;
        }
    }
}
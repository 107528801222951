footer {
    padding: 20px 0;
    /* Reduced padding */
    background-color: #00091a;
    /* Dark navy blue background */
    color: red;
    /* Light gray text for better contrast against dark background */

    .containersd {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        /* Align items at the start to reduce height */
    }

    .footer-logo {
        margin-bottom: 10px;
        /* Reduce margin */
    }

    p {
        margin: 0 0 10px;
        color: #e0e0e0;
        /* Reduced paragraph margin */
    }

    .social-logo {
        p {
            margin-bottom: 5px;
            /* Reduce margin between text and icons */
        }

        ul {
            display: flex;
            gap: 10px;
            /* Space between social icons */
            padding: 0;
            list-style: none;

            a {
                color: #e0e0e0;
                /* Gold color for social icons */
                font-size: 1.5rem;
                /* Slightly larger icons for better visibility */
            }
        }
    }

    .footer-link {
        p {
            margin-bottom: 10px;
            /* Reduced margin */
            color: #e0e0e0;
            /* Gold color for section title */
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;

            li {
                margin-bottom: 5px;
                /* Reduced space between links */

                a {
                    color: #e0e0e0;
                    /* Light gray color for links */
                    text-decoration: none;

                    &:hover {
                        color: #e0e0e0;
                        /* Gold color on hover for visibility */
                        text-decoration: underline;
                        /* Underline on hover for visibility */
                    }
                }
            }
        }
    }

    .footer-contact {
        p {
            margin-bottom: 10px;
            /* Reduced margin */
            color: #e0e0e0;
            /* Gold color for section title */
        }

        .contact-list {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            /* Reduced space between contact items */

            .contact-icon {
                margin-right: 10px;

                /* Space between icon and text */
                img {
                    filter: invert(100%);
                    /* Invert icon color to make it visible on dark background */
                }
            }

            h5 {
                margin: 0;
                color: #e0e0e0;
                /* Light gray color for contact info */
            }
        }
    }

    .copyright-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        /* Reduced padding */
        border-top: 1px solid #1a2a44;
        /* Slightly lighter border to distinguish sections */

        .copy-text,
        .copy-links {
            margin: 0;
            padding: 0;
            font-size: 0.875rem;
            /* Slightly smaller font size */
            color: #e0e0e0;
            /* Light gray color for text */
        }

        .copy-links ul {
            display: flex;
            gap: 15px;
            /* Space between links */
            list-style: none;
            padding: 0;
            margin: 0;

            a {
                color: #e0e0e0;
                /* Gold color for links */
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                    /* Underline on hover for visibility */
                }
            }
        }
    }
}
@import '../../sass/colors.scss';

.symptoms-section {
    margin-top: 150px;
    background: #FADBE2;

    .section-title {
        text-align: center;

        p {
            max-width: 700px;
            margin: auto;
        }
    }

}

// Mobile Responsive
@media (max-width:575px){
    .symptoms-section {
        margin-top: 50px;
    }
}

@media (min-width:576px) and (max-width: 767px){
    .symptoms-section {
        margin-top: 50px;
    }
}
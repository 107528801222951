@import '../../sass/colors.scss';

.appointment-section {
    padding-bottom: 70px;
    background-color: #f9f9f9;
    /* Light background color for contrast */

    .section-title {
        margin-bottom: 30px;

        h2,
        h3 {
            color: #FFD700;
            /* Gold color for titles */
            margin-bottom: 10px;
        }

        h2 {
            font-size: 2rem;
            /* Main title size */
            font-weight: bold;
        }

        h3 {
            font-size: 1.5rem;
            /* Subtitle size */
            font-weight: 600;
        }
    }

    .google-map {
        height: 600px;
        position: relative;
        margin-bottom: 30px;

        iframe {
            width: 100%;
            height: 100%;
            border: 0;
            border-radius: 8px;
        }

        .location-name {
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
            background: $color-white;
            padding: 15px;
            width: 80%;
            margin: auto;
            display: flex;
            align-items: center;
            border-radius: 30px;

            svg {
                background: $color-blue;
                color: $color-white;
                width: 60px;
                height: 50px;
                padding: 10px;
                line-height: 50px;
                border-radius: 15px;
                border: 5px solid #DBDEFA;
            }

            p {
                margin-left: 20px;
                margin-bottom: 0;
                font-weight: 600;
                color: $color-black;
            }
        }
    }

    .appointment-form-area {
        padding-left: 50px;
        background: #fff;
        /* White background for the form area */
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        /* Light shadow for depth */

        p {
            color: #333;
            /* Dark gray for description text */
            font-size: 1rem;
            line-height: 1.6;
            margin-bottom: 20px;
        }
    }
}

.appointment-call {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .icon {
        width: 45px;
        height: 45px;
        margin-right: 30px;
        background: $color-white;
        line-height: 45px;
        text-align: center;
        border-radius: 12px;
        position: relative;
        z-index: 1;
        box-shadow: 0 0 5px 3px rgb(0 0 0 / 8%);
    }

    .call-text {
        p {
            font-size: 14px;
            text-transform: uppercase;
            color: $color-blue;
            font-weight: 700;
            margin-bottom: 5px;
        }

        h6 {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 700;
        }
    }
}

// Mobile Responsive
@media (max-width: 575px) {
    .appointment-section {
        .appointment-form-area {
            padding-left: 0;

            .form-group {
                margin-bottom: 10px;

                label {
                    font-size: 12px;
                    margin-bottom: 5px;
                }

                .form-control {
                    height: 45px;
                    border-radius: 10px;
                }
            }
        }
    }
}
.what-we-stand-for {
    background: linear-gradient(to bottom, #f7f7f7, #ffffff);
    padding: 4rem 0;

    .container {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 1rem;
    }

    .title {
        font-size: 2.5rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 2.5rem;
        color: #333;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;

        @media (max-width: 1024px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }

        .grid-item {
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            padding: 1.5rem;
            text-align: center;
            transition: transform 0.3s ease;

            &:hover {
                transform: translateY(-5px);
            }

            .icon-container {
                font-size: 2.5rem;
                color: #d4af37;
                /* Gold color for icons */
                margin-bottom: 1rem;
            }

            .grid-title {
                font-size: 1.8rem;
                font-weight: 600;
                color: #d4af37;
                /* Gold color for titles */
                margin-bottom: 1rem;
            }

            .grid-description {
                font-size: 1rem;
                color: #555;
                line-height: 1.6;
            }
        }
    }
}